import { useEffect } from 'react';
import ReactModal from 'react-modal';

type Options = {
  resolveRootElement?: (document: Document) => HTMLElement | null;
};

export const useInitModal = (options?: Options) => {
  useEffect(() => {
    initModal(document, options);
  }, []);
};

export const initModal = (document: Document, options?: Options) => {
  if (document) {
    const element = options?.resolveRootElement?.(document) || 'body';
    (ReactModal as any).setAppElement(element);
  }
};
