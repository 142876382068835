import { MantineColor, MantineColorsTuple } from '@mantine/core';

const ExtendedCustomColorsConst = [
  'sky-blue',
  'bright-blue',
  'deep-blue',
  'komo-blue',
  'pale-blue',
  'deep-red',
  'pale-purple',
  'komo-primary',
  'deep-pink',
  'komo-yellow'
] as const;
export type ExtendedCustomColors = (typeof ExtendedCustomColorsConst)[number];
export const ExtendedCustomColorsArray =
  ExtendedCustomColorsConst as unknown as ExtendedCustomColors[];

export const ExtendedColorPalettes: Record<
  ExtendedCustomColors,
  MantineColorsTuple
> = {
  'komo-blue': [
    '#E5F4FF',
    '#CDE2FF',
    '#9BC2FF',
    '#64A0FF',
    '#3984FE',
    '#1D72FE',
    '#0969FF',
    '#0058E4',
    '#004ECC',
    '#0043B5'
  ],
  'sky-blue': [
    '#e1f9ff',
    '#ccedff',
    '#9ad7ff',
    '#64c1ff',
    '#3baefe',
    '#20a2fe',
    '#099cff',
    '#0088e4',
    '#0078cd',
    '#0069b6'
  ],
  'bright-blue': [
    '#e5f4ff',
    '#cde2ff',
    '#9bc2ff',
    '#64a0ff',
    '#3984fe',
    '#1d72fe',
    '#0969ff',
    '#0058e4',
    '#004ecc',
    '#0043b5'
  ],
  'pale-blue': [
    '#eef3ff',
    '#dce4f5',
    '#b9c7e2',
    '#94a8d0',
    '#748dc1',
    '#5f7cb8',
    '#5474b4',
    '#44639f',
    '#39588f',
    '#2d4b81'
  ],
  'deep-blue': [
    '#ebefff',
    '#d5dafc',
    '#a9b1f1',
    '#7b87e9',
    '#5362e1',
    '#3a4bdd',
    '#2d3fdc',
    '#1f32c4',
    '#182cb0',
    '#0b259c'
  ],
  'pale-purple': [
    '#f2f0ff',
    '#e0dff2',
    '#bfbdde',
    '#9b98ca',
    '#7d79ba',
    '#6a65b0',
    '#605bac',
    '#504c97',
    '#464388',
    '#3b3979'
  ],
  'deep-red': [
    '#ffeaec',
    '#fdd4d6',
    '#f4a7ac',
    '#ec777e',
    '#e64f57',
    '#e3353f',
    '#e22732',
    '#c91a25',
    '#b31220',
    '#9e0419'
  ],
  'komo-primary': [
    '#E5F4FF',
    '#CDE2FF',
    '#9BC2FF',
    '#64A0FF',
    '#3984FE',
    '#1D72FE',
    '#0969FF',
    '#0058E4',
    '#004ECC',
    '#0043B5'
  ],
  'deep-pink': [
    '#feebf1',
    '#f9d3df',
    '#f5a2bd',
    '#f36f99',
    '#f1477b',
    '#f13268',
    '#f2275e',
    '#d71e4f',
    '#c11645',
    '#a8053b'
  ],
  'komo-yellow': [
    '#fffee1',
    '#fcface',
    '#f9f49f',
    '#f5ed6c',
    '#f2e842',
    '#f1e426',
    '#f0e313',
    '#d5c900',
    '#bdb200',
    '#a39900'
  ]
};

export const ExtendedColorPaletteKeys: MantineColor[] = [
  'dark',
  'gray',
  'red',
  'pink',
  'grape',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'green',
  'lime',
  'yellow',
  'orange',
  'teal',
  ...ExtendedCustomColorsArray
];
