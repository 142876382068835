import Cookies from 'js-cookie';

const ConsentCookieName = 'komo.consent';

export const ConsentCookieService = {
  /**
   * Check if the user has specified their cookie consent settings.
   * If the ConsentCookie is present then we assume the user has accepted the prompt.
   */
  hasSavedCookieSettings(): boolean {
    return Cookies.get(ConsentCookieName) !== undefined;
  },

  /**
   * Check if the user has consented to nonessential cookies.
   */
  hasConsented(): boolean {
    return Cookies.get(ConsentCookieName) === '1';
  },

  /**
   * Set the nonessential cookie consent state
   * @param consent Whether or not the user has consented to nonessential cookies
   */
  setConsent(consent: boolean) {
    Cookies.set(ConsentCookieName, consent ? '1' : '0', {
      expires: 365,
      secure: true
    });
  }
};
