import React, { Component, PropsWithChildren, ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProp {
  fallback: ReactNode;
  onError?: (e: any) => void;
}

export class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProp>,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(e, info) {
    console.error(e, info);
    this.props?.onError?.(e);
    this.setState({
      hasError: true
    });
  }

  render() {
    if (this.state.hasError) {
      return <>{this.props.fallback}</>;
    }
    return this.props.children;
  }
}
