import { ColorPalette } from '@komo-tech/core/models/ColorPalette';
import type {
  ClassNames as MantineClassNames,
  ElementProps as MantineElementProps,
  Factory as MantineFactory,
  FactoryPayload as MantineFactoryPayload,
  FloatingPosition as MantineFloatingPosition,
  GetStylesApi as MantineGetStylesApi,
  MantineColor,
  MantineSize,
  MantineSpacing,
  MantineStyleProps,
  MantineTheme,
  MantineThemeColors,
  MantineThemeComponent,
  PartialVarsResolver as MantinePartialVarsResolver,
  Styles as MantineStyles,
  StylesApiProps as MantineStylesApiProps,
  VariantColorResolverResult as MantineVariantColorResolverResult
} from '@mantine/core';
import { ElementType as ReactElementType } from 'react';

export type UiSize = MantineSize;
export type FactoryPayload = MantineFactoryPayload;
export type Factory<Payload extends FactoryPayload> = MantineFactory<Payload>;
export type BaseColor = MantineColor;
export type Styles<Payload extends FactoryPayload> = MantineStyles<Payload>;
export type ClassNames<Payload extends FactoryPayload> =
  MantineClassNames<Payload>;
export type PartialVarsResolver<Payload extends FactoryPayload> =
  MantinePartialVarsResolver<Payload>;
export type ThemeComponent = MantineThemeComponent;
export type ThemeColors = MantineThemeColors;
export type ThemeSpacing = MantineSpacing;
export type ThemeStyleProps = MantineStyleProps;
export type Theme = MantineTheme;
export type VariantColorResolverResult = MantineVariantColorResolverResult;
export type ElementProps<
  ElementType extends ReactElementType,
  PropsToOmit extends string = never
> = MantineElementProps<ElementType, PropsToOmit>;

export type GetStylesApi<Payload extends FactoryPayload> =
  MantineGetStylesApi<Payload>;
export type StylesApiProps<Payload extends FactoryPayload> =
  MantineStylesApiProps<Payload>;
export type FloatingPosition = MantineFloatingPosition;

export const SiteThemeDefaults = {
  bgColor: '#EFEFF4',
  accentBgColor: '#000000',
  accentColor: '#FFFFFF',
  headerBgColor: '#000000',
  headerColor: '#FFFFFF',
  footerBgColor: '#000000',
  footerColor: '#FFFFFF'
};

export const KomoColorConstants = {
  surfaceBg: '#FFFFFF',
  surfaceC: '#000000',
  surfaceBd: '#DEE2E6' //gray.3
};

export interface ISiteThemeProperties {
  getBackgroundFilterColor: () => string | undefined;
  BackgroundColor?: string;

  HeaderBackgroundColor?: string;
  HeaderTextColor?: string;

  AccentBackgroundColor?: string;
  AccentTextColor?: string;

  FooterBackgroundColor?: string;
  FooterTextColor?: string;

  getColorPalettes: () => ColorPalette[];
}
