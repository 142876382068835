import { ISiteThemeProperties, SiteThemeDefaults } from './types';

export const resolveSiteThemeValues = (
  properties?: ISiteThemeProperties
): SiteThemeProps => {
  return {
    bgFilter: properties?.getBackgroundFilterColor(),
    bgColor: properties?.BackgroundColor || SiteThemeDefaults.bgColor,

    accentBgColor:
      properties?.AccentBackgroundColor || SiteThemeDefaults.accentBgColor,
    accentColor: properties?.AccentTextColor || SiteThemeDefaults.accentColor,
    headerBgColor:
      properties?.HeaderBackgroundColor ||
      properties?.AccentBackgroundColor ||
      SiteThemeDefaults.headerBgColor,
    headerColor:
      properties?.HeaderTextColor ||
      properties?.AccentTextColor ||
      SiteThemeDefaults.headerColor,
    footerBgColor:
      properties?.FooterBackgroundColor ||
      properties?.AccentBackgroundColor ||
      SiteThemeDefaults.footerBgColor,
    footerColor:
      properties?.FooterTextColor ||
      properties?.AccentTextColor ||
      SiteThemeDefaults.footerColor
  };
};

export interface SiteThemeProps {
  bgFilter?: string;
  bgColor: string;

  headerBgColor: string;
  headerColor: string;

  accentBgColor: string;
  accentColor: string;

  footerBgColor: string;
  footerColor: string;
}

export interface IHasSiteTheme {
  site: SiteThemeProps;
}
