import { isIP, isIPv6 } from '@komo-tech/core/utils/ip';
import { IncomingMessage } from 'http';
import { ParsedUrlQuery } from 'querystring';

type NextRouter = {
  pathname: string;
  query: ParsedUrlQuery;
};

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isProductionEnvironment = () =>
  process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT === 'production';

export const isStagingEnvironment = () =>
  process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT === 'staging';

export const isMobileOnServer = (req: IncomingMessage) => {
  if (!req) {
    return false;
  }
  return (
    req.headers['user-agent']?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ) || false
  );
};

/**
 * gets the base url with any dynamic routes filled in.
 * needed to open competition from the single card route /card/[cardId]
 */
export const getBaseUrlFromNextRouter = (router: NextRouter) => {
  const { pathname, query } = router;
  let url = pathname;
  Object.keys(query).forEach((key) => {
    url = url.replace(`[${key}]`, query[key].toString());
  });
  return url;
};

/**
 * Returns the HTTP referer header value or empty string.
 * @param req The current request.
 * @returns The HTTP referer.
 */
export const getReferer = (req: IncomingMessage) => {
  if (!req) {
    return '';
  }
  return req.headers.referer?.toString() || '';
};

/**
 * Returns the referer domain from the referer header.
 * @param req The current request.
 * @returns The referer domain or empty string.
 */
export const getRefererDomain = (req: IncomingMessage) => {
  if (!req) {
    return '';
  }
  try {
    const referer = getReferer(req);
    if (!referer) {
      return '';
    }
    const url = new URL(referer);
    return url.host;
  } catch {
    return '';
  }
};

/**
 * Returns the HTTP user agent header value or empty string.
 * @param req The current request.
 * @returns The HTTP user agent.
 */
export const getUserAgent = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  return req.headers['user-agent']?.toString() || '';
};

/**
 * Returns the client region header value or empty string.
 * @param req The current request.
 * @returns The client region.
 */
export const getClientRegion = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  return req.headers['x-client-region']?.toString() || '';
};

/**
 * Returns the client city header value or empty string.
 * @param req The current request.
 * @returns The client city.
 */
export const getClientCity = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  return req.headers['x-client-city']?.toString() || '';
};

/**
 * Returns the client city lat long header value or empty string.
 * @param req The current request.
 * @returns The client city lat long.
 */
export const getClientCityLatLong = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  return req.headers['x-client-citylatlong']?.toString() || '';
};

/**
 * Try to determine the client IP address based on a number of sources.
 * Attempts to use the X-Client-IP header, then falls back to the
 * X-Forwarded-For header, and finally attempts to get the remote IP address for
 * the current request socket.
 * @param req The current request.
 * @returns The client IP address, if able to find one.
 */
export const tryGetClientIpAddress = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }

  const xClientIp = tryGetXClientIp(req);
  if (xClientIp) return xClientIp;

  const forwardedClientIp = tryGetXForwardedForClientIp(req);
  if (forwardedClientIp) return forwardedClientIp;

  const socketRemoteIp = tryGetSocketRemoteIp(req);
  if (socketRemoteIp) return socketRemoteIp;

  return '';
};

const tryGetXClientIp = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  const xClientIp = req.headers['x-client-ip']?.toString() || '';
  if (isIP(xClientIp) || isIPv6(xClientIp)) {
    return xClientIp;
  }

  return '';
};

const tryGetXForwardedForClientIp = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }
  const xForwardedFor = req.headers['x-forwarded-for']?.toString() || '';

  if (!xForwardedFor) {
    return '';
  }

  const parts = xForwardedFor.split(',');
  if (parts.length === 0) {
    return '';
  }

  const clientIp = parts[0];

  if (isIP(clientIp) || isIPv6(clientIp)) {
    return clientIp;
  }

  return '';
};

const tryGetSocketRemoteIp = (req: IncomingMessage): string => {
  if (!req) {
    return '';
  }

  const remoteAddress = req.socket?.remoteAddress || '';
  if (isIP(remoteAddress) || isIPv6(remoteAddress)) {
    return remoteAddress;
  }

  return '';
};
