import React, { FC } from 'react';

import style from './SiteErrored.module.css';

interface Props {
  errorCode?: string | number;
  errorMessage?: string;
}

export const SiteErrored: FC<Props> = ({ errorCode, errorMessage }) => (
  <div className={style.erroredWrapper}>
    <div className={style.siteError}>
      <img
        className={style.errorIcon}
        src="/assets/images/icon-light-error.svg"
        alt="Error"
      />
      <div className={style.errorCode}>{errorCode || '404'}</div>
      <div className={style.errorTitle}>
        {errorMessage || 'Something went wrong'}
      </div>
      <div className={style.errorSubTitle}>
        You may try{' '}
        <div
          className={style.refreshPageLink}
          onClick={() => window.location.reload()}
        >
          refreshing the page
        </div>{' '}
        or try again later
      </div>
    </div>
  </div>
);
